import {
  Avatar,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { handleApiRequest } from "../../hooks/callApi";
import SprintDetailsActions from "./SprintDetailsActions";

export default function TaskCard({ item, refetchSprintTasks }) {
  return (
    <>
      <Card sx={{}}>
        <CardHeader
          title={item.name}
          subheader={`Task Points: ${item.points}`}
          titleTypographyProps={{
            variant: "body2",
            color: "black",
            fontWeight: 600,
          }}
          subheaderTypographyProps={{ variant: "caption" }}
          action={
            <ButtonGroup>
              <SprintDetailsActions
                refetchSprintTasks={refetchSprintTasks}
                sprintTask={item}
              />
              <IconButton
                size="small"
                sx={{ color: "red" }}
                onClick={async () => {
                  try {
                    const res = await handleApiRequest(
                      `/api/sprint-tasks/${item.id}`,
                      "delete"
                    );
                    console.log(res);
                    await refetchSprintTasks();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <DeleteOutline color="inherit" fontSize="inherit" />
              </IconButton>
            </ButtonGroup>
          }
        />
        <CardContent sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Avatar
            sx={{
              fontSize: "12px",
              width: "24px",
              height: "24px",
              color: "#333",
              fontWeight: "bold",
              background:
                "linear-gradient(180deg, #B31E4444 0%, #F7B31E44 100%)",
            }}
          >
            {item?.first_name?.[0] + item?.last_name?.[0]}
          </Avatar>
          <Typography variant="body2" color="textSecondary" component="p">
            {!item.end_date
              ? "Not Provided"
              : new Date(item.end_date).toLocaleDateString("en-us", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
