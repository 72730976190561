import { Card, CardHeader, CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks";
import BurnDownChart from "./Charts/BurnDownChart";
import GanttChart from "./Charts/GanttChart";
import MemberContributionBarChart from "./Charts/MemberContributionBarChart";
import SprintProgressRadialBar from "./Charts/SprintProgressRadialBar";
import SprintVelocityChart from "./Charts/SprintVelocityChart";
import TaskStatusPieChart from "./Charts/TaskStatusPieChart";
export default function Dashboard() {
  const { projectId } = useParams();
  const { data: sprintsData } = useFetch(`/api/sprints/project/${projectId}`);
  const { data: sprintsVelocityData } = useFetch(
    `/api/sprints/velocity/${projectId}`
  );
  const { data: contributionData } = useFetch(
    `/api/sprints/member-contribution/${projectId}`
  );
  const { data: burnDownData } = useFetch(
    `/api/sprints/burn-down/${projectId}`
  );
  const { data: taskDistributionData } = useFetch(
    `/api/sprints/task-status-distribution/${projectId}`
  );
  const { data: sprintProgressData } = useFetch(
    `/api/sprints/progress/${projectId}`
  );
  return (
    <Grid container spacing={2}>
      {/* Sprint Gantt Chart */}
      <Grid item md={12}>
        <Card>
          <CardHeader title="Sprint Gantt Chart" />
          {!sprintsData ? (
            <CircularProgress />
          ) : (
            <GanttChart data={sprintsData} />
          )}
        </Card>
      </Grid>
      {/* Sprint Task Status Pie Chart */}
      <Grid item md={6}>
        <Card sx={{ height: 320 }}>
          <CardHeader title="All Sprint Task Status In Pie Chart" />
          {!taskDistributionData ? (
            <CircularProgress />
          ) : (
            <TaskStatusPieChart data={taskDistributionData} />
          )}
        </Card>
      </Grid>
      {/* Sprint Progress Radial Bar */}
      <Grid item md={6}>
        <Card sx={{ height: 320 }}>
          <CardHeader title="All Sprint Completion Status" />
          {!sprintProgressData ? (
            <CircularProgress />
          ) : (
            <SprintProgressRadialBar data={sprintProgressData} />
          )}
        </Card>
      </Grid>
      {/* Sprint Member Contribution Chart */}
      <Grid item md={12}>
        <Card>
          <CardHeader title="Task Distribution Among Members In All Sprints" />
          {!contributionData ? (
            <CircularProgress />
          ) : (
            <MemberContributionBarChart data={contributionData} />
          )}
        </Card>
      </Grid>
      {/* Sprint Velocity Chart */}
      <Grid item md={12}>
        <Card>
          <CardHeader title="Overall Sprint Velocity Of Project" />
          {!sprintsVelocityData ? (
            <CircularProgress />
          ) : (
            <SprintVelocityChart data={sprintsVelocityData} />
          )}
        </Card>
      </Grid>

      {/* Sprint Burn-Down Chart */}
      <Grid item md={12}>
        <Card>
          <CardHeader title="Sprint Burn-Down Chart" />
          {!burnDownData ? (
            <CircularProgress />
          ) : (
            <BurnDownChart data={burnDownData} />
          )}
        </Card>
      </Grid>
      {/*  */}
    </Grid>
  );
}
