import React from "react";
import Chart from "react-apexcharts";

const SprintProgressRadialBar = ({ data }) => {
  const options = {
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          name: {
            show: true,
            offsetY: -10,
            color: "#888",
            fontSize: "14px",
          },
          value: {
            show: true,
            color: "#111",
            fontSize: "24px",
            offsetY: 10,
          },
        },
      },
    },
    colors: ["#00A100"],
    fill: {
      type: "solid",
    },
    title: {
      text: "Sprint Progress",
      align: "center",
    },
    labels: ["Progress"],
  };

  const series = [
    ((data?.completedPoints / data?.totalPoints) * 100).toFixed(2),
  ];

  return (
    <Chart options={options} series={series} type="radialBar" height={350} />
  );
};

export default SprintProgressRadialBar;
